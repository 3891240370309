import { useStaticQuery, graphql } from "gatsby"

export default () => {
  const helpCenterNodes = useStaticQuery(graphql`
    {
      allAirtableHelpCenter(sort: { fields: data___order, order: ASC }) {
        nodes {
          data {
            section
            question
            answer
          }
        }
      }
    }
  `)

  let faqNodes = helpCenterNodes?.allAirtableHelpCenter?.nodes

  // Fetch all unique sections + filter undefined headers
  let tempHelpCenterSections = []
  let helpCenter = faqNodes
    .map(data => {
      if (!tempHelpCenterSections.includes(data.data.section)) {
        tempHelpCenterSections.push(data.data.section)
        return { header: data.data.section, fields: [] }
      }
      return null
    })
    .filter(header => header != null)

  // Map all question and answer to their corresponding sections
  for (let counter = 0; counter < helpCenter.length; counter++) {
    helpCenter[counter].fields = faqNodes.filter(
      data => data.data.section === helpCenter[counter].header
    )
  }

  return helpCenter
}
