import * as Yup from "yup"
const REQUIRED_MESSAGE = "This field is required"

export const contactUsValidation = inclusions => {
  let validationObject = {}

  if (inclusions?.includes("fullName"))
    validationObject.fullName = Yup.string().required(REQUIRED_MESSAGE)
  if (inclusions?.includes("email"))
    validationObject.email = Yup.string()
      .email("Please input a valid email")
      .required(REQUIRED_MESSAGE)
  if (inclusions?.includes("mobile"))
    validationObject.mobileNumber = Yup.string()
      .min(11, "Please input a valid mobile number in this format: 09991234567")
      .max(11, "Please input a valid mobile number in this format: 09991234567")
      .matches(
        /^09[0-9]*$/,
        "Please input a valid mobile number in this format: 09991234567"
      )
      .required(REQUIRED_MESSAGE)
  if (inclusions?.includes("inquiry"))
    validationObject.subject = Yup.object().shape({
      value: Yup.string()
        .required(REQUIRED_MESSAGE)
        .nullable(),
    })
  if (inclusions?.includes("message"))
    validationObject.emailBody = Yup.string().required(REQUIRED_MESSAGE)

  return Yup.object().shape(validationObject)
}
