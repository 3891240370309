import React, { Fragment } from "react"

import Layout from "layout/Layout"
import Container from "layout/Container"
import Collapsible from "elements/Collapsible"
import ContactUs from "../components/StaticPages/HelpCenter/ContactUs"

import getHelpCenterContent from "../services/airtable/getHelpCenter"
import marked from "marked"

export default () => {
  let helpCenterContent = getHelpCenterContent()

  return (
    <Layout seoTitle="Help Center">
      <Container isCentered>
        <h2>
          Frequently Asked Questions <br className="is-hidden-mobie" />
          for Nintedanib (Ofev)
        </h2>
        <hr />
        {helpCenterContent.map(node => {
          return (
            <Fragment>
              <h4>{node.header}</h4>
              {node.fields.map(faq => {
                return (
                  <Collapsible title={faq.data.question}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: marked(faq.data.answer),
                      }}
                    ></div>
                  </Collapsible>
                )
              })}
            </Fragment>
          )
        })}
        <ContactUs />
      </Container>
    </Layout>
  )
}
